import React from 'react';
import LoaderFund from '../assets/degen_fund.png';

const DegenFund = () => {
  return (
    <div id='degenfund' className='w-full py-16 px-4 bg-[#5735B5]'>
      <div className='max-w-[1240px] mx-auto'>
        <div className='grid md:grid-cols-2 md:gap-4'>
          <div className='flex flex-col justify-center mx-4'>
            <h1 className='text-[#E7C600] h1-title font-days-one text-4xl pb-5'>
              DEGEN FUND
            </h1>
            <p className='text-lg text-white'>
              The Degen Fund is the treasury of this community. It will be owned and operated by the Degen Club, and profits made will be reinvested along with a percentage going to Degen Pass holders.
              <br/>
              Investments will be mainly focused on the LOAD Ecosystem, Based Projects, and Degen Tings.
            </p>
          </div>
          <img src={LoaderFund} alt="Loader About" className='w-[420px] mx-auto my-4 md:order-first'/>
        </div>
        
        <div className='grid lg:grid-cols-3 gap-6'>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-[#5955D0] hover:scale-105 duration-300'>
            <h1 className='text-[#E7C600] h1-title font-days-one text-2xl text-center py-2'>LOAD ECOSYSTEM</h1>
            <p className='text-lg text-white text-justify'>
              Any proposal that gets approved by the Degen Club.
              From a telegram bot, to a lottery game for the Degen Club Web App, anything that is usefull/fun for a true Degen.
              Access will be exclusive for $LOAD holders.
            </p>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-[#5955D0] hover:scale-105 duration-300'>
            <h1 className='text-[#E7C600] h1-title font-days-one text-2xl text-center py-2'>BASED PROJECTS</h1>
            <p className='text-lg text-white text-justify'>
              It is common sense that one of the best ways to make it in this space is by investing in blue-chip projects.
              There are tons of good teams, with top-notch products, that just keep grinding no matter what market conditions are.
              Real recognize real, so a percentage of the Degen Fund will be allocated to those projects.
            </p>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-[#5955D0] hover:scale-105 duration-300'>
            <h1 className='text-[#E7C600] h1-title font-days-one text-2xl text-center py-2'>DEGEN TINGS</h1>
            <p className='text-lg text-white text-justify'>
            This is where Degen's needs are met.
            Degen Tings include anything the club members agree to do as whole.
            It could be aping into a newly launched project, a poker tourney, or anything else that comes to our degen minds. Deez tings will take place at the Degen Club Chat, and anyone behaving as a bad actor will be banned, no matter the amount of supply they are holding.
            </p>
          </div>
        </div>





      </div>
    </div>
  )
}

export default DegenFund