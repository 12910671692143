import React from 'react';
import Pass from '../assets/pass.png';

const DegenPass = () => {
  return (
    <div id='degenpass' className='w-full py-16 px-4 bg-[#5955D0]'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 md:gap-4'>
        <div className='flex flex-col justify-center mx-4'>
          <h1 className='text-[#E7C600] h1-title font-days-one text-4xl pb-5'>
            DEGEN PASS
          </h1>
          <p className='text-lg text-white'>
          Degen Pass is a low supply NFT collection, that will get profit sharing from the Degen Fund.
          <br/>
          100 exclusive pieces, 0.1 ETH per mint - 100% of the income injected into the Fund.
          <br/>
          The mint will start for Club members only, and will be open to $LOAD holders after an amount of time if there is any supply left.
          </p>
        </div>
        <img src={Pass} alt="Degen Pass" className='w-[450px] mx-auto my-12 shadow-2xl border-solid border-8 border-[#E7C600]'/>
      </div>
    </div>
  )
}

export default DegenPass