import React from "react";
import LoaderLore from "../assets/loader_lore.png";

const Lore = () => {
  return (
    <div id="lore" className="w-full py-16 px-4 bg-[#5955D0]">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 md:gap-4">
        <div className="flex flex-col justify-center mx-4">
          <h1 className="text-[#E7C600] h1-title font-days-one text-4xl pb-5">
            LORE
          </h1>
          <p className="text-lg text-white">
            "Degen trading or Degen mode is when a trader does trading without
            due diligence and research, aping into signals and FOMO into pumps.
            A Degen Trader does not know about metrics like FDV or TVL nor do
            they care. They will buy because the asset logo looks cute, or
            because the slogan is memeable, or because some twit-famous anime
            girl on the internet says she's looking into crypto and the first
            two shill comments gets more likes than others."
          </p>
          <p className="text-white text-xs font-bold pt-2">
            By{" "}
            <a
              href="https://www.coingecko.com/en/glossary/degen"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#E7C600] underline"
            >
              CoinGecko
            </a>
          </p>
        </div>
        <img
          src={LoaderLore}
          alt="Pepe Love"
          className="w-[450px] mx-auto my-4"
        />
      </div>
    </div>
  );
};

export default Lore;
