import React from 'react';
import LoaderEarn from '../assets/degen_2_earn.png';

const DegenEarn = () => {
  return (
    <div id='degenearn' className='w-full py-16 px-4 bg-[#5735B5]'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 md:gap-4'>
        <div className='flex flex-col justify-center mx-4'>
          <h1 className='text-[#E7C600] h1-title font-days-one text-4xl pb-5'>
            DEGEN2EARN
          </h1>
          <p className='text-lg text-white'>
          We all know that degens can't resist fomo too long... So let's degen together!
          <br/>
          Every week, we will select two Club members that are down bad, and give them .1 ETH to make it all back in one trade.
          <br/>
          As for degens that mainly catch moonshots, do not worry! We will not forget you. At a later stage, X members of the Club with the best P&L ratio will be able to manage a portion of the treasury, taking royalties on profits.
          </p>
        </div>
        <img src={LoaderEarn} alt="Loader About" className='w-[420px] mx-auto md:order-first'/>
      </div>
    </div>
  )
}

export default DegenEarn