import React from 'react';
import LoaderAbout from '../assets/loader_about.png'

const About = () => {
  return (
    <div id='about' className='w-full py-16 px-4 bg-[#5735B5]'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 md:gap-4'>
        <div className='flex flex-col justify-center mx-4'>
          <h1 className='text-[#E7C600] h1-title font-days-one text-4xl pb-5'>
            ABOUT
          </h1>
          <p className='text-lg text-white'>
          Degens come in many shapes and sizes. We possess different lifestyles and qualities that make us unique. However, amongst us Degens, there is one thing that we all share in its purest and most undeniable form…. The Degen spirit.
          <br/>
          And Life of a Degen is just about that! Giving all the Degenerates a true home.
          </p>
        </div>
        <img src={LoaderAbout} alt="Loader About" className='w-[420px] mx-auto my-4 md:order-first'/>
      </div>
    </div>
  )
}

export default About