import React from 'react';
import DegenClubImage from '../assets/degen_club.png'

const DegenClub = () => {
  return (
    <div id='degenclub' className='w-full py-16 px-4 bg-[#5955D0]'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 md:gap-4'>
        <div className='flex flex-col justify-center mx-4'>
          <h1 className='text-[#E7C600] h1-title font-days-one text-4xl pb-5'>
            DEGEN CLUB
          </h1>
          <p className='text-lg text-white'>
          The DAO of LOAD. Holders with at least .5% of the supply are eligible to be a part of this elite circle of Degens. This will be the central component, since it will be responsible for managing the Degen Fund (Treasury) and the LOAD Ecosystem.
          <br/>
          Only members of the Club will be eligible for Degen2Earn as well as get whitelist opportunities for a Degen Pass. This Club will have two places of interaction:
          <br/>
          - Telegram Collab.Land channel, the place where true Degens hang out. Here, low level/fast paced decisions are made and every vote counts the same;
          <br/>
          - Degen Club Web App, where DAO proposals and high level decisions take place. Voting power will be based on the percentage of supply a Degen holds.
          </p>
        </div>
        <img src={DegenClubImage} alt="Degen Club" className='w-[450px] mx-auto my-12 shadow-2xl border-solid border-8 border-[#E7C600]'/>
      </div>
    </div>
  )
}

export default DegenClub