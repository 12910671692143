import React from 'react'
import Lore from './components/Lore';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Tokenomics from './components/Tokenomics';
import About from './components/About';
import Nfds from './components/Nfds';
import Vision from './components/Vision';
import DegenClub from './components/DegenClub';
import DegenEarn from './components/DegenEarn';
import DegenPass from './components/DegenPass';
import DegenFund from './components/DegenFund';

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Lore />
      <About />
      <Tokenomics />
      <Vision />
      <DegenClub />
      <DegenEarn />
      <DegenPass />
      <DegenFund />
      <Nfds />
      <Footer />
    </div>
  );
}

export default App;
