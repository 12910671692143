import React from "react";
import Typed from "react-typed";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { ReactComponent as DextIcon } from "../assets/dextools.svg";

const Hero = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-[#E7C600] md:text-8xl sm:text-7xl text-6xl font-rubik-marker p-2 hero-dynamic-text">
          L.O.A.D.
        </p>
        <Typed
          className="md:text-5xl sm:text-4xl text-2xl font-days-one md:py-6 py-2"
          strings={["Finally!", "Welcome Home, Degen `&#129309`"]}
          typeSpeed={140}
          backSpeed={110}
        />
        <a
          href="https://app.uniswap.org/#/swap?outputCurrency=0xBea3801a9Db8f1C0f41b2Aeb167D09E852E561B5"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#E7C600] w-[200px] rounded-md font-days-one my-4 mx-auto py-2 drop-shadow-[0_1px_2px_rgba(255,255,255,1)] hover:bg-white hover:text-[#E7C600] hover:drop-shadow-[0_1px_6px_rgba(231,198,0,1)] hover:scale-110 duration-300"
        >
          $LOAD UP!
        </a>
        <div className="flex mt-6 mx-auto justify-between gap-12">
          <a
            href="https://twitter.com/Life_OfADegen"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:scale-125 duration-300 hover:text-[#E7C600] drop-shadow-[0_3px_4px_rgba(231,198,0,1)]"
          >
            <FaTwitter size={40} />
          </a>
          <a
            href="https://t.me/LifeOfADegen"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:scale-125 duration-300 hover:text-[#E7C600] drop-shadow-[0_3px_3px_rgba(231,198,0,1)]"
          >
            <FaTelegramPlane size={40} />
          </a>
          <a
            href="https://www.dextools.io/app/en/ether/pair-explorer/0x5a9f1af6642e94c9545520808e7a41060d5d9033"
            target="_blank"
            rel="noopener noreferrer"
            id="dext-icon"
            className="hover:scale-125 duration-300 drop-shadow-[0_3px_4px_rgba(231,198,0,1)]"
          >
            <DextIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
