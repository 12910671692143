import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import LoadIcon from '../assets/icon.png'
import { Link } from 'react-scroll'

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      <a href='/' className='flex items-center'>
        <img src={LoadIcon} alt='LOAD Logo' className='h-20 mt-2' />
      </a>
      <ul className='hidden lg:flex tracking-widest'>
        <li className='p-4 nav-item hover:scale-125 duration-300 hover:text-[#E7C600] cursor-pointer'>
          <Link to="tokenomics" spy={true} smooth={true} offset={2} duration={800}>Tokenomics</Link>
        </li>
        <li className='p-4 nav-item hover:scale-125 duration-300 hover:text-[#E7C600] cursor-pointer'>
          <Link to="vision" spy={true} smooth={true} offset={2} duration={900}>Vision</Link>
        </li>
        <li className='p-4 nav-item hover:scale-125 duration-300 hover:text-[#E7C600] cursor-pointer'>
          <Link to="degenclub" spy={true} smooth={true} offset={2} duration={900}>Degen Club</Link>
        </li>
        <li className='p-4 nav-item hover:scale-125 duration-300 hover:text-[#E7C600] cursor-pointer'>
          <Link to="degenearn" spy={true} smooth={true} offset={2} duration={900}>Degen2Earn</Link>
        </li>
        <li className='p-4 nav-item hover:scale-125 duration-300 hover:text-[#E7C600] cursor-pointer'>
          <Link to="degenpass" spy={true} smooth={true} offset={2} duration={900}>Degen Pass</Link>
        </li>
        <li className='p-4 nav-item hover:scale-125 duration-300 hover:text-[#E7C600] cursor-pointer'>
          <Link to="degenfund" spy={true} smooth={true} offset={2} duration={900}>Degen Fund</Link>
        </li>
        <li className='p-4 nav-item hover:scale-125 duration-300 hover:text-[#E7C600] cursor-pointer'>
          <Link to="nfds" spy={true} smooth={true} offset={2} duration={700}>NFDs</Link>
        </li>
      </ul>
      <div onClick={handleNav} className='block lg:hidden'>
        {nav
          ? <AiOutlineClose size={24} className='cursor-pointer hover:scale-125 duration-300 hover:text-[#E7C600] drop-shadow-[0_3px_4px_rgba(231,198,0,1)]' />
          : <AiOutlineMenu className='cursor-pointer hover:scale-125 duration-300 hover:text-[#E7C600] drop-shadow-[0_3px_4px_rgba(231,198,0,1)]' size={24} />}
      </div>
      <div className={nav ? 'fixed left-0 top-0 z-50 w-[60%] h-full shadow-2xl bg-[#5955D0] ease-in-out duration-500' : 'fixed left-[-100%]'}>
        <a href='/' className='flex items-center my-4 mx-4'>
          <img src={LoadIcon} alt='LOAD Logo' className='h-20' />
        </a>
        <ul className='p-4 tracking-widest'>
          <li className='p-4 nav-item'>
            <Link className='cursor-pointer hover:text-[#E7C600] duration-300' to="tokenomics" spy={true} smooth={true} offset={2} duration={800} onClick={handleNav}>Tokenomics</Link>
          </li>
          <li className='p-4 nav-item'>
            <Link className='cursor-pointer hover:text-[#E7C600] duration-300' to="vision" spy={true} smooth={true} offset={2} duration={900} onClick={handleNav}>Vision</Link>
          </li>
          <li className='p-4 nav-item'>
            <Link className='cursor-pointer hover:text-[#E7C600] duration-300' to="degenclub" spy={true} smooth={true} offset={2} duration={800} onClick={handleNav}>Degen Club</Link>
          </li>
          <li className='p-4 nav-item'>
            <Link className='cursor-pointer hover:text-[#E7C600] duration-300' to="degenearn" spy={true} smooth={true} offset={2} duration={900} onClick={handleNav}>Degen2Earn</Link>
          </li>
          <li className='p-4 nav-item'>
            <Link className='cursor-pointer hover:text-[#E7C600] duration-300' to="degenpass" spy={true} smooth={true} offset={2} duration={800} onClick={handleNav}>Degen Pass</Link>
          </li>
          <li className='p-4 nav-item'>
            <Link className='cursor-pointer hover:text-[#E7C600] duration-300' to="degenfund" spy={true} smooth={true} offset={2} duration={900} onClick={handleNav}>Degen Fund</Link>
          </li>
          <li className='p-4 nav-item'>
            <Link className='cursor-pointer hover:text-[#E7C600] duration-300' to="nfds" spy={true} smooth={true} offset={2} duration={700} onClick={handleNav}>NFDs</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar