import React from 'react';
import Nfd from '../assets/nfd_classified.png';

const Nfds = () => {
  return (
    <div id='nfds' className='w-full py-16 px-4 bg-[#5955D0]'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 md:gap-4'>
        <div className='flex flex-col justify-center mx-4'>
          <h1 className='text-[#E7C600] h1-title font-days-one text-4xl pb-5'>
            NON-FUNGIBLE DEGENS
          </h1>
          <p className='text-lg text-white'>
            More info about the NFT collection soon!
          </p>
        </div>
        <img src={Nfd} alt="NFD" className='w-[400px] mx-auto my-12 shadow-2xl border-solid border-8 border-[#E7C600]'/>
      </div>
    </div>
  )
}

export default Nfds