import React from 'react';
import { FaTelegramPlane, FaTwitter } from 'react-icons/fa';
import { ReactComponent as DextIcon } from '../assets/dextools.svg';
import LoadIcon from '../assets/icon.png'

const Footer = () => {
  return (
    <div className='w-full py-14 px-8 text-white'>
      <div className='max-w-[1240px] mx-auto'>
        <img src={LoadIcon} alt='LOAD Logo' className='h-16 mb-4' />
        <div className='grid md:grid-cols-2'>
          <div>
            <p className='text-xs font-bold'>Disclaimer</p>
            <p className='text-xs'>
              Life Of A Degen is a community owned, decentralized project, that no person or entity has ownership upon but the community supporting its growth.
              No information on this website, or any other platform with LOAD related content, should be considered financial advice.
              Trade at your own risk.
            </p>
          </div>
          <div className='mx-auto mt-10 md:mt-0 flex flex-col'>
            <a
              href='https://app.uniswap.org/#/swap?outputCurrency=0xc458905442cae83c1db43382db0cd9eb5bb89529' target="_blank" rel="noopener noreferrer"
              className='bg-[#E7C600] w-[180px] rounded-md font-days-one text-sm text-center py-2 drop-shadow-[0_1px_2px_rgba(255,255,255,1)] hover:bg-white hover:text-[#E7C600] hover:drop-shadow-[0_1px_6px_rgba(231,198,0,1)] hover:scale-110 duration-300'>
                $LOAD UP!
            </a>
            <div className='flex mt-4 justify-between'>
              <a href='https://twitter.com/Life_OfADegen' target="_blank" rel="noopener noreferrer" className='hover:scale-125 duration-300 hover:text-[#E7C600] drop-shadow-[0_3px_4px_rgba(231,198,0,1)]'>
                <FaTwitter size={30} />
              </a>
              <a href='https://t.me/LifeOfADegen' target="_blank" rel="noopener noreferrer" className='hover:scale-125 duration-300 hover:text-[#E7C600] drop-shadow-[0_3px_4px_rgba(231,198,0,1)]'>
                <FaTelegramPlane size={30} />
              </a>
              <a href='https://www.dextools.io/app/en/ether/pair-explorer/0x5a9f1af6642e94c9545520808e7a41060d5d9033' target="_blank" rel="noopener noreferrer" id='dext-icon' className='hover:scale-125 duration-300 drop-shadow-[0_3px_4px_rgba(231,198,0,1)]'>
                <DextIcon height={28} width={28} />
              </a>
            </div>
          </div>
        </div>
        <p className='text-xs font-bold text-center mt-10'>For Degens, By Degens&trade;</p>
      </div>
    </div>
  )
}

export default Footer