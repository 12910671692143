import React from 'react';
import LoadVision from '../assets/load_vision.png';

const Vision = () => {
  return (
    <div id='vision' className='w-full py-16 px-4 bg-[#5735B5]'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 md:gap-4'>
        <div className='flex flex-col justify-center mx-4'>
          <h1 className='text-[#E7C600] h1-title font-days-one text-4xl pb-5'>
            VISION
          </h1>
          <p className='text-lg text-white'>
            We want LOAD to be a real community driven project, with its foundation on the meme/movement. So, we propose a vision instead of a roadmap, and handle the decision making to the community from a very early stage.
            <br/>
            Rather than the typical approach of having a community supporting a team, we want to make it the other way around and deliver as the community pleases.
            <br/>
            The goal is to move towards a tax free, 100% independent project, but that can only be achieved when the ecosystem is self-sustainable.
          </p>
        </div>
        <img src={LoadVision} alt="Load Vision" className='w-[500px] mx-auto my-12 shadow-2xl border-solid border-8 border-[#E7C600] md:order-first'/>
      </div>
    </div>
  )
}

export default Vision