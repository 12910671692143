import React from 'react'
import TaxIcon from '../assets/icons/tax.png';
import CommunityIcon from '../assets/icons/community.png';
import WalletIcon from '../assets/icons/max_wallet.png';
import LockIcon from '../assets/icons/lock.png';
import CaIcon from '../assets/icons/ca.png';
import {AiFillCopy} from 'react-icons/ai';

const Tokenomics = () => {
  const LOAD_CA = "0xBea3801a9Db8f1C0f41b2Aeb167D09E852E561B5";

  const copyCaToClipboard = () => {
    navigator.clipboard.writeText(LOAD_CA);
  };

  return (
    <div id='tokenomics' className='w-full py-20 px-4 md:px-8 bg-[#5955D0]'>
      <div className='max-w-[1240px] mx-auto text-white'>
        <h1 className='text-[#E7C600] h1-title font-days-one text-4xl text-center'>TOKENOMICS</h1>
        <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-8 pt-20'>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-[#5735B5] hover:scale-105 duration-300'>
            <img className='h-24 mx-auto' src={TaxIcon} alt="Tax" />
            <h1 className='text-6xl lg:text-5xl font-days-one text-center pt-3'>4%</h1>
            <h2 className='text-3xl lg:text-2xl font-days-one text-center'>BUY/SELL</h2>
            <p className='text-xs text-center font-bold'>1% Liquidity, 3% Marketing & Degen Fund</p>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-[#5735B5] hover:scale-105 duration-300'>
            <img className='w-32 mx-auto mt-3' src={CommunityIcon} alt="Community" />
            <h1 className='text-6xl lg:text-5xl font-days-one text-center pt-8'>100%</h1>
            <h2 className='text-3xl lg:text-2xl font-days-one text-center pb-8'>COMMUNITY</h2>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-[#5735B5] hover:scale-105 duration-300'>
            <img className='w-24 mx-auto' src={WalletIcon} alt="Max Wallet" />
            <h1 className='text-6xl lg:text-5xl font-days-one text-center pt-6'>2%</h1>
            <h2 className='text-3xl lg:text-2xl font-days-one text-center pb-8'>MAX WALLET</h2>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-[#5735B5] hover:scale-105 duration-300'>
            <img className='h-24 mx-auto' src={LockIcon} alt="Liquidity Lock" />
            <h1 className='text-6xl lg:text-5xl font-days-one text-center pt-3'>LIQ.</h1>
            <h2 className='text-3xl lg:text-2xl font-days-one text-center pb-8'>LOCKED</h2>
          </div>
        </div>
        <div className='w-full shadow-xl flex flex-col p-4 mt-12 mb-4 rounded-lg bg-[#5735B5] hover:scale-105 duration-300'>
          <img className='h-24 mx-auto' src={CaIcon} alt="CA" />
          <h1 className='text-4xl lg:text-3xl text-center pt-6 pb-2 font-days-one break-words'>{LOAD_CA}</h1>
          <AiFillCopy onClick={copyCaToClipboard} size={22} className='mx-auto mb-4 cursor-pointer hover:text-[#E7C600] drop-shadow-[1px_1px_1px_rgba(231,198,0,1)]' />
        </div>
      </div>
    </div>
  )
}

export default Tokenomics